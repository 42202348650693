:root {
  --nearBlack: #0b0b0b;
  --wide: .04em;
  --wider: .05em;
  --one-third: 33.3333%;
  --two-thirds: 66.6667%;
  --eleven-twelves: 91.6667%;
  --mobileWidth: 93vw;
}

@font-face {
  font-family: BlinkMac;
  font-weight: 200;
  font-style: normal;
  src: url("BlinkMac-Thin-subset.02d3cfc0.woff2") format("woff2"), url("BlinkMac-Thin-subset.5b8d648a.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: BlinkMac;
  font-weight: 100;
  font-style: normal;
  src: url("BlinkMac-Ultralight-subset.b4f6313b.woff2") format("woff2"), url("BlinkMac-Ultralight-subset.f37a56ac.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: BlinkMac;
  font-weight: 300;
  font-style: normal;
  src: url("BlinkMac-Light-subset.a13a5032.woff2") format("woff2"), url("BlinkMac-Light-subset.cbfad874.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: BlinkMac;
  font-weight: 400;
  font-style: normal;
  src: url("BlinkMac-Regular-subset.2ee79cfb.woff2") format("woff2"), url("BlinkMac-Regular-subset.b80617da.woff") format("woff");
  font-display: swap;
}

html {
  font-family: BlinkMac, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

body {
  background: var(--nearBlack);
  margin: 0;
}

#app {
  color: #fff;
  width: 100%;
  top: 0;
  left: 0;
}

.page {
  width: 100%;
  min-height: 100%;
}

.page__content-container {
  justify-content: center;
  align-items: center;
  margin-bottom: 18rem;
  display: flex;
}

.content__width {
  width: 80vw;
  max-width: 1300px;
}

#loading__container {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 300;
  display: flex;
  top: 50vh;
}

.flex-row-center {
  align-items: center;
  display: flex;
}

.nav__page-container {
  color: #fff;
  justify-content: flex-end;
  padding: .25rem 0;
  display: flex;
}

#nav__page {
  padding: .25rem 0;
  display: flex;
}

.nav__page-link {
  opacity: .6;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: var(--wide);
  cursor: pointer;
  z-index: 100;
  margin-left: 1rem;
  padding: 0;
  font-size: 16.3px;
  font-weight: 200;
  line-height: 24px;
  text-decoration: none;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.nav__page-link:hover {
  opacity: 1;
  cursor: pointer;
}

.header__container {
  justify-content: center;
  width: 100%;
  margin: 60px 0 12px;
  display: flex;
}

.header__content-container {
  width: 80vw;
  max-width: 1300px;
}

.header__text-container {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 .25rem;
  display: flex;
}

.header__text-container h1 {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .04rem;
  margin: 0;
  padding-bottom: 0;
  font-size: 6rem;
  font-weight: 100;
  line-height: 5.9rem;
  transition: none;
}

.header__line {
  opacity: .5;
  background-color: #fff;
  width: 100%;
  height: 1px;
}

.footer {
  color: #fff;
  text-align: center;
  width: 100%;
  padding: 3rem 0;
  font-weight: 200;
  position: absolute;
  bottom: 0;
  left: 0;
}

button {
  letter-spacing: var(--wide);
  color: #000;
  box-sizing: border-box;
  background-color: #d1d5db;
  border: 1px solid #fff;
  border-radius: .125rem;
  justify-content: center;
  align-items: center;
  height: 3rem;
  margin-right: 1.5rem;
  padding: 0 1.4rem;
  font-family: BlinkMac;
  font-size: .875rem;
  font-weight: 400;
  text-decoration: none;
  display: flex;
}

button:hover {
  cursor: pointer;
  color: #fff;
  background-color: #9ca3af;
  border: 1px solid #0000;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.page__home {
  opacity: 0;
  height: 100vh;
  position: fixed;
  overflow: hidden;
}

#home__container {
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  display: flex;
}

#nav__home-container {
  width: 100%;
  margin: 2.15rem 0 0 0;
  position: absolute;
  right: 3rem;
}

#nav__home {
  justify-content: flex-end;
  display: flex;
}

.nav__home-link {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: var(--wide);
  opacity: .6;
  cursor: pointer;
  z-index: 100;
  margin-left: 1rem;
  padding: .5rem 0;
  font-size: 16.3px;
  font-weight: 200;
  text-decoration: none;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.nav__home-link:hover {
  opacity: 1;
  cursor: pointer;
}

#home__linebox {
  z-index: 10;
  position: fixed;
  inset: max(20px, 2.5vmin);
}

.home__linebox-line {
  opacity: .5;
  background-color: #fff;
}

#home__linebox-top, #home__linebox-bottom {
  z-index: 100;
  background-color: #fff;
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
}

#home__linebox-right, #home__linebox-left {
  z-index: 100;
  background-color: #fff;
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
}

#home__linebox-top {
  top: 0;
}

#home__linebox-right {
  right: 0;
}

#home__linebox-bottom {
  bottom: 0;
}

#home__linebox-left {
  left: 0;
}

.home__heading-container {
  color: #fff;
  z-index: 10;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 .5rem .75rem 3rem;
  display: flex;
  position: fixed;
  bottom: max(20px, 2.5vmin);
}

.home__heading-container h1 {
  letter-spacing: .04rem;
  color: #fff;
  font-size: 6rem;
  font-weight: 100;
  line-height: 1;
}

.home__heading-container h2 {
  letter-spacing: var(--wide);
  padding: 0 0 .48rem 1rem;
  font-size: 1.5rem;
  font-weight: 100;
}

.home__heading-container h1, .home__heading-container h2 {
  margin: 0;
}

.page__projects {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.projects__categories-container {
  justify-content: start;
  align-items: center;
  display: flex;
}

#projects__content-container {
  z-index: 10;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  max-width: 1300px;
  margin-top: 50px;
  display: flex;
}

.projects__category-input {
  letter-spacing: var(--wider);
  display: none;
}

.projects__category-label {
  letter-spacing: var(--wider);
  text-transform: uppercase;
  opacity: .6;
  cursor: pointer;
  box-sizing: border-box;
  color: #fff;
  border-bottom: 1px solid #0000;
  margin: 0 1rem 0 .25rem;
  font-size: 16.3px;
  font-weight: 200;
  line-height: 1.5rem;
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

input:checked + label {
  color: #fff;
  opacity: 1;
  letter-spacing: var(--wider);
  border-bottom: 1px solid #fff;
}

.projects__category-label:hover {
  opacity: 1;
}

.project__container {
  flex-direction: column;
  width: 100%;
  max-width: 1300px;
  display: flex;
}

.project__content-container {
  cursor: pointer;
  border: 1px #fff;
  align-items: flex-start;
  width: 100%;
  min-height: 275px;
  margin-bottom: 1rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.project__content-container:hover {
  transform: scale(1.02);
}

.project__image-container {
  width: var(--one-third);
  border-radius: .125rem;
  min-width: 300px;
  max-width: 384px;
}

.project__image {
  cursor: pointer;
  width: 100%;
}

.project__text-container {
  color: #fff;
  flex-direction: column;
  justify-content: space-between;
  width: 66%;
  height: 100%;
  margin: 0 0 0 2rem;
  display: flex;
}

.project__text-container h2 {
  letter-spacing: var(--wide);
  margin: 0;
  font-size: 1.875rem;
  font-weight: 300;
  line-height: 36px;
}

#project__featured-icon {
  width: 1rem;
  height: 1rem;
  margin: .25rem 0 0 .5rem;
}

.project__text-container h3 {
  text-transform: uppercase;
  opacity: .8;
  letter-spacing: var(--wide);
  margin: 0 0 16px;
  font-size: 16.3px;
  font-weight: 300;
  line-height: 24px;
}

.project__text-description {
  color: #fff;
  letter-spacing: var(--wide);
  margin: 0;
  font-size: 16.3px;
  font-weight: 200;
  line-height: 24px;
}

.project__builtWith-item {
  border-bottom: 1px solid #fff;
  font-weight: 300;
}

.icon__star {
  width: 1rem;
  height: 1rem;
  margin: .25rem 0 0 .5rem;
}

.project__buttons-container {
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 2rem;
  display: flex;
}

.project__button {
  letter-spacing: var(--wide);
  color: #000;
  box-sizing: border-box;
  background-color: #d1d5db;
  border: 1px solid #fff;
  border-radius: .125rem;
  justify-content: center;
  align-items: center;
  width: 5.5rem;
  height: 2.8rem;
  margin-right: 1.5rem;
  font-size: .875rem;
  font-weight: 400;
  text-decoration: none;
  display: flex;
}

.project__button:hover {
  cursor: pointer;
  color: #fff;
  background-color: #9ca3af;
  border: none;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.project__button-disabled {
  color: #424242;
  cursor: default;
  letter-spacing: var(--wide);
  box-sizing: border-box;
  background-color: #7e7f81;
  border: 1px solid #717375;
  border-radius: .125rem;
  justify-content: center;
  align-items: center;
  width: 5.5rem;
  height: 2.8rem;
  margin-right: 1.5rem;
  font-size: .875rem;
  font-weight: 400;
  text-decoration: none;
  display: flex;
}

.project__line-bottom {
  opacity: .2;
  background-color: #fff;
  width: 100%;
  max-width: 1300px;
  height: 1px;
  margin: 3rem 0;
}

.page__about {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

#about__content-container {
  z-index: 10;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 80vw;
  max-width: 1300px;
  margin: 68px 0 18rem;
  display: flex;
}

.about__portrait-container {
  justify-content: center;
  display: flex;
}

.about__portrait-container img {
  cursor: pointer;
  border-radius: .125rem;
  width: 400px;
  height: 400px;
}

.about__text-content-container {
  color: #fff;
  flex-direction: column;
  max-width: 550px;
  margin: 0 0 0 3rem;
  display: flex;
}

.about__text-content-container h2 {
  letter-spacing: .04rem;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 0 1.25rem;
  font-size: 3.75rem;
  font-weight: 200;
  line-height: 60px;
}

.about__text-content-container p {
  letter-spacing: var(--wide);
  font-size: 16.3px;
  font-weight: 200;
  line-height: 24px;
}

.about__text-content-container h4 {
  letter-spacing: var(--wider);
  color: #fff;
  border-bottom: 1px solid #fff;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 0 1.5rem;
  font-size: 1.125rem;
  font-weight: 200;
}

.about__section-education {
  margin: 2rem 0 0;
}

.about__section-education ul {
  padding: 0 0 0 1rem;
  font-size: 16.3px;
  font-weight: 400;
  list-style: disc;
}

.about__section-education li {
  letter-spacing: var(--wide);
  padding: 1rem 0 .15rem .75rem;
}

.about__section-education span {
  font-weight: 200;
}

.about__section-skills {
  flex-direction: column;
  width: 100%;
  margin-top: 4.5rem;
  display: flex;
}

.about__skills-container {
  justify-content: flex-start;
  align-items: flex-start;
  width: 600px;
  display: flex;
}

.about__section-skills ul {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 600px;
  padding: 0;
  display: grid;
}

.about__section-skills li {
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  display: flex;
}

.about__skill {
  color: #fff;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5rem;
  font-weight: 200;
  display: flex;
}

.about__skill img, .about__skill p {
  position: relative;
  top: .25rem;
}

.about__skill p {
  text-transform: uppercase;
  letter-spacing: .1em;
  height: 22px;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.about__skill img {
  opacity: .8;
  width: 26px;
  height: 26px;
  margin-right: .75rem;
}

.about__section-links {
  justify-content: flex-start;
  width: 100%;
  margin-top: 1.5rem;
  display: flex;
}

#about__button-resume {
  opacity: 1;
  display: absolute;
  transition-property: opacity;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  left: 0;
}

#about__resume-confirmation {
  opacity: 0;
  display: absolute;
  justify-content: center;
  align-items: center;
  height: 48px;
  transition-property: opacity;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  left: 0;
}

#about__resume-confirmation p {
  margin-right: .75rem;
}

#about__resume-confirmation img {
  opacity: .9;
  margin: 0;
  padding: .75rem;
}

.about__confirmation-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

#about__resume-confirmation img:hover {
  opacity: .7;
  cursor: pointer;
}

.about__section-personal {
  margin-top: 5rem;
}

.page__contact {
  justify-content: center;
  align-items: center;
  min-height: 95vh;
  display: flex;
}

.contact__container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  max-width: 1300px;
  margin: 138px 0 0;
  display: flex;
}

.contact__heading {
  color: #fff;
  text-align: left;
  letter-spacing: var(--wide);
  margin: 0 0 8rem;
  font-size: 1.25rem;
  font-weight: 100;
}

.contact__buttons-container {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0 720px;
  display: flex;
}

.contact__links-container {
  letter-spacing: var(--wide);
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  display: flex;
}

.contact__links-container a {
  text-decoration: none;
}

.contact__links-container span {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
  display: flex;
}

.contact__links-container p {
  color: #fff;
  margin: 0;
  padding: .2rem 0 0;
  font-size: .875rem;
  font-weight: 200;
}

.contact__links-container img {
  opacity: .9;
  filter: invert();
  width: 2rem;
  height: 2rem;
  margin: 0 0 .25rem;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.contact__links-container img:hover {
  opacity: .6;
}

.contact__email-container {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0 0 0 2rem;
  display: flex;
}

@media (width <= 450px) {
  .home__heading-container h1 {
    font-size: 1.875rem;
  }

  .header__text-container h1 {
    margin-top: .3rem;
    font-size: 4.5rem;
  }

  .header__container {
    margin: 0 0 .5rem;
  }

  #nav__page {
    padding: 0 0 .5rem;
  }

  .projects__category-label {
    font-size: .875rem;
  }

  .nav__page-link {
    margin: 0 .9rem;
  }
}

@media (width <= 640px) {
  .home__heading-container h2 {
    font-size: 1rem;
  }

  #nav__home-container {
    right: 2.5rem;
  }

  .nav__link {
    font-size: .875rem;
  }

  .home__heading-container h2 {
    padding: .15rem 0 .35rem 0;
  }

  .header__text-container {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }

  #projects__content-container, .contact__container, .project__text-container, .about__skills-container ul {
    width: var(--mobileWidth);
  }

  .project__image-container {
    width: var(--eleven-twelves);
  }

  .project__text-container h2 {
    font-size: 1.5rem;
  }

  .project__buttons-container {
    margin-top: 2rem;
  }

  .about__portrait-container img {
    width: 350px;
    height: 350px;
  }

  .about__text-content-container h2 {
    font-size: 3rem;
  }

  .about__skill {
    font-size: .75rem;
  }

  .contact__heading {
    text-align: center;
    margin: 0 0 5rem;
    font-size: 1.125rem;
  }

  .contact__buttons-container {
    width: var(--mobileWidth);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 710px;
  }

  .contact__links-container {
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }

  .contact__links-container span {
    width: 4rem;
  }

  .contact__email-container {
    justify-content: center;
    margin: 0;
  }

  #contact__email-button {
    margin: 1rem 0 0;
  }

  #about__content-container, .about__text-content-container {
    width: var(--mobileWidth);
  }

  .about__section-headers {
    justify-content: center;
    display: flex;
  }

  .about__section-skills li {
    justify-content: center;
  }

  #about__button-resume, .about__confirmation-container {
    margin-left: 1rem;
  }

  .about__section-skills {
    justify-content: center;
    align-items: center;
  }
}

@media (width <= 768px) {
  .home__heading-container {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 .5rem .75rem 2.45rem;
  }

  .header__content-container, .content__width {
    width: var(--mobileWidth);
  }

  .projects__category-label {
    line-height: 1rem;
  }

  .about__skills-container {
    width: min-content;
  }
}

@media (width <= 1024px) {
  .nav__page-container {
    padding: .375rem 0;
  }

  .project__content-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: -moz-fit-content;
    height: fit-content;
  }

  .project__image-container {
    max-width: none;
  }

  .project__text-container {
    margin: 1rem 0 0;
  }

  .about__text-content-container {
    margin: 2rem 0 0;
  }

  #about__content-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (width >= 451px) and (width <= 640px) {
  .home__heading-container h1 {
    font-size: 2.25rem;
  }

  .header__text-container h1 {
    margin: 0 0 .1rem;
    font-size: 4.5rem;
  }

  .header__text-container {
    margin-bottom: .1rem;
  }

  #nav__page {
    padding: 0 0 .3rem;
  }

  .header__container {
    margin: 30px 0 12px;
  }

  .nav__page-link {
    margin: 0 1rem;
  }
}

@media (width >= 641px) and (width <= 768px) {
  .home__heading-container h1 {
    font-size: 3rem;
  }

  .home__heading-container h2 {
    padding: .15rem 0 .35rem 0;
  }

  .header__text-container {
    margin: 0;
  }

  .header__text-container h1 {
    margin: 0 0 6px;
    font-size: 3.75rem;
    line-height: 3.75rem;
  }

  .projects__category-label {
    margin: 0 1rem 0 .25rem;
  }

  .project__image-container {
    width: var(--two-thirds);
  }

  .project__text-container {
    width: 80vw;
  }

  .project__buttons-container {
    margin-top: 1.5rem;
  }

  #nav__page {
    padding: 0 0 .3rem 0;
  }

  .about__section-skills {
    justify-content: center;
    align-items: center;
  }

  .about__section-skills ul {
    width: 550px;
  }

  .about__section-skills li {
    justify-content: center;
  }
}

@media (width >= 769px) and (width <= 1024px) {
  .home__heading-container {
    padding: 0 .5rem .75rem 2.5rem;
  }

  .home__heading-container h1 {
    font-size: 3.75rem;
  }

  .home__heading-container h2 {
    padding: 0 0 .17rem 1rem;
  }

  .header__text-container h1 {
    margin: 0 0 .375rem;
    font-size: 3.75rem;
    line-height: .9;
  }

  .project__image-container {
    width: var(--two-thirds);
  }

  .project__text-container {
    width: 80vw;
  }

  .project__buttons-container {
    margin-top: 1.5rem;
  }

  .about__portrait-container img {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
  }
}

@media (width >= 1025px) and (width <= 1280px) {
  .home__heading-container h1 {
    font-size: 4.5rem;
  }

  .home__heading-container h2 {
    padding: 0 0 .24rem 1rem;
  }

  .about__portrait-container img {
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
  }
}

/*# sourceMappingURL=index.dc768857.css.map */
